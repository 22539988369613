import { GenericDialog } from "./GenericDialog";

const WaitDialog = ({ show, message }: { show: boolean, message: string }) => {
    if (!show) return null; // If 'show' is false, don't render anything

    return (
        <GenericDialog isOpen={show} closeModal={() => { }}>
            <div className="bg-white border py-2 px-5 rounded-lg flex items-center flex-col">
                <div className="loader-dots block relative w-20 h-5 mt-2">
                    <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
                    <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
                    <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
                    <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
                </div>
                <div className="text-gray-500 text-xs font-medium mt-2 text-center">
                    {message}
                </div>
            </div>
        </GenericDialog>
    );
}

export default WaitDialog;
import { possettings } from "./models/possettings";
import { useEffect, useRef, useState } from "react";
import { ftQueueItem, ftReceiptJournal, journal, journalAADE, journalQueueItems, journalReceiptJournals } from "./services/posapi";
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from "@tanstack/react-query";
import WaitDialog from "./dialogs/WaitDialog";
import ReceiptRequest from "./models/ReceiptRequest";
import ReceiptResponse from "./models/ReceiptResponse";

interface InvoiceParsedResult {
    uid: string;
    mark: string;
    xmlPayload: string;
    issuerVat: string;
    issueDate: string;
    transmissionFailure: string;
    qrCodeUrl?: string;
}
export type POSAppProps = {
    settings: possettings;
};

const POSApp = ({ settings }: POSAppProps) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [receiptLink, setReceiptLink] = useState<string>();
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const queueitemsQuery = useQuery({
        queryKey: ['queueItems'], queryFn: async () => {
            const response = await journalQueueItems(settings.cashboxid, settings.accesstoken);
            return response as ftQueueItem[];
        }
    });
    const receiptJournalsQuery = useQuery({
        queryKey: ['receiptJournals'], queryFn: async () => {
            const response = await journalReceiptJournals(settings.cashboxid, settings.accesstoken);
            return response as ftReceiptJournal[];
        }
    });
    const aadeQuery = useQuery({
        queryKey: ['aade'], queryFn: async () => {
            const response = await journalAADE(settings.cashboxid, settings.accesstoken);
            return response;

        }
    });

    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.src = "about:blank";
            iframeRef.current.onload = () => {
                if (iframeRef.current!.src == 'about:blank') {
                    iframeRef.current!.src = `${receiptLink}?appId=${uuidv4()}`;
                }
            };
        }
    }, [receiptLink]);

    if (queueitemsQuery.isLoading) {
        return <WaitDialog show={true} message="Loading queueitems. .." />;
    }
    if (receiptJournalsQuery.isLoading) {
        return <WaitDialog show={true} message="Loading receiptJournalsQuery. .." />;
    }
    if (aadeQuery.isLoading) {
        return <WaitDialog show={true} message="Loading aadeQuery. .." />;
    }
    if (!aadeQuery.data) {
        return <WaitDialog show={true} message="Loading AADE..." />;
    }

    if (receiptJournalsQuery.data == undefined || queueitemsQuery.data == undefined || aadeQuery.data == undefined) {
        return <h1>Failed to load, please refresh</h1>
    }

    let receiptJournalsDict = receiptJournalsQuery.data.reduce((acc, receiptJournal) => {
        acc[receiptJournal.ftQueueItemId] = receiptJournal;
        return acc;
    }, {} as { [key: string]: ftReceiptJournal });
    let queueItemsDict = queueitemsQuery.data.reduce((acc, queueItem) => {
        acc[queueItem.ftQueueItemId] = queueItem;
        return acc;
    }, {} as { [key: string]: ftQueueItem });
    let parsedInvoices = parseInvoicesWithDOMParser(aadeQuery.data);
    let aadeItemsDict = parsedInvoices.reduce((acc, aadeItem) => {
        acc[aadeItem.mark] = aadeItem;
        return acc;
    }, {} as { [key: string]: InvoiceParsedResult });

    let receipts: Receipt[] = [];
    for (let i = 0; i < receiptJournalsQuery.data.length; i++) {
        let receiptJournal = receiptJournalsQuery.data[i];
        let queueItem = queueItemsDict[receiptJournal.ftQueueItemId];
        if (queueItem == undefined) {
            continue;
        }

        let receiptRequest = JSON.parse(queueItem.request) as ReceiptRequest;
        let receiptResponse = JSON.parse(queueItem.response) as ReceiptResponse;
        let marker = receiptResponse?.ftSignatures.find(x => x.Caption == "invoiceMark")?.Data;
        if (marker == undefined) {
            continue;
        }
        let markerReceipt = aadeItemsDict[marker];
        let receipt: Receipt = {
            receiptJournal: receiptJournal,
            queueItem: queueItem as ftQueueItem,
            receiptRequest: receiptRequest,
            receiptResponse: receiptResponse,
            aadeData: markerReceipt
        }
        receipts.push(receipt);
    }
    receipts = receipts.filter(
        (invoice) =>
            invoice.aadeData?.mark.toLowerCase().includes(searchTerm.toLowerCase()) ||
            invoice.aadeData?.issuerVat.toLowerCase().includes(searchTerm.toLowerCase()) ||
            invoice.aadeData?.issueDate.toLowerCase().includes(searchTerm.toLowerCase())
    );
    receipts = receipts.sort((a, b) => {
        if (a.receiptJournal.ftReceiptMoment > b.receiptJournal.ftReceiptMoment) {
            return -1;
        }
        if (a.receiptJournal.ftReceiptMoment < b.receiptJournal.ftReceiptMoment) {
            return 1;
        }
        return 0;
    });
    return (
        <div className="flex flex-grow justify-center  overflow-auto gap-5">
            <div className="flex flex-grow justify-center flex-col w-10/12">
                <input
                    type="text"
                    placeholder="Μ.ΑΡ.Κ, ΑΦΜ ή ημερ/νια"
                    className="w-full p-2 mb-4 border rounded-md text-black"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="h-full overflow-auto">
                    {receipts.length > 0 ? (
                        <div className="space-y-4 p-5">
                            {receipts.map((invoice, i) => (
                                <div key={i} className="border p-4 rounded-md bg-white text-gray-900 flex flex-row">
                                    <div>
                                        {invoice.aadeData?.transmissionFailure == "1" ? <span className="inline-flex items-center rounded-md bg-red-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10">
                                            Απώλεια Διασύνδεσης Οντότητας - Παρόχου
                                        </span> : <></>}

                                        {invoice.aadeData?.transmissionFailure == "2" ? <span className="inline-flex items-center rounded-md bg-red-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10">
                                            Invoice Transmission Failure 2 - Απώλεια Διασύνδεσης Παρόχου – ΑΑΔΕ"
                                        </span> : <></>}

                                        {!invoice.aadeData?.transmissionFailure ? <span className="inline-flex items-center rounded-md bg-green-500 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                            Επιτυχής αποστολή
                                        </span> : <></>}
                                        <p><span className="font-semibold">Αριθμός παραστατικού: </span> {invoice.receiptResponse?.ftReceiptIdentification}</p>
                                        <p><span className="font-semibold">Μ.ΑΡ.Κ:</span> {invoice.aadeData?.mark}</p>
                                        <p><span className="font-semibold">ΑΦΜ εκδότη:</span> {invoice.aadeData?.issuerVat}</p>
                                        <p><span className="font-semibold">Ημερ/νια έκδοσης:</span> {invoice.aadeData?.issueDate}</p>
                                        <p>
                                            <a target="_blank" className={`flex w-48 items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`} onClick={() => setReceiptLink(`https://receipts-sandbox.fiskaltrust.eu/${invoice.queueItem.ftQueueId}/${invoice.queueItem.ftQueueItemId}`)}>Εμφάνιση</a>
                                        </p>
                                    </div>
                                    <Timeline queueItem={invoice.queueItem} receiptRequest={invoice.receiptRequest} receiptResponse={invoice.receiptResponse} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-gray-500">No results found in {receipts.length} receipts.</p>
                    )}
                </div>
            </div>
            {receiptLink ? <iframe ref={iframeRef} src="about:blank" className="w-full h-full" /> : <div className="w-full h-full">Παρακαλώ διαλέξτε απόδειξη ή αναζητήστε στα αριστερά</div>}
        </div>
    );
};

const parseInvoicesWithDOMParser = (xmlString: string): InvoiceParsedResult[] => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
    const invoices = Array.from(xmlDoc.getElementsByTagName('invoice'));

    return invoices.map((invoiceNode) => {
        const uid = invoiceNode.querySelector('uid')?.textContent || '';
        const mark = invoiceNode.querySelector('mark')?.textContent || '';
        const qrCodeUrl = invoiceNode.querySelector('qrCodeUrl')?.textContent || '';
        const transmissionFailure = invoiceNode.querySelector('transmissionFailure')?.textContent || '';
        const xmlPayload = invoiceNode.outerHTML; // Get the XML payload as a string

        // Parse issuer VAT number and issue date
        const issuerVat = invoiceNode.querySelector('issuer > vatNumber')?.textContent || '';
        const issueDate = invoiceNode.querySelector('invoiceHeader > issueDate')?.textContent || '';

        return { uid, mark, xmlPayload, issuerVat, issueDate, transmissionFailure: transmissionFailure, qrCodeUrl };
    });
};


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

import { CheckIcon, ComputerDesktopIcon, CloudIcon } from '@heroicons/react/20/solid'

export type TimelineProps = {
    queueItem: ftQueueItem;
    receiptResponse: ReceiptResponse;
    receiptRequest: ReceiptRequest;
};
const Timeline = ({ queueItem, receiptRequest, receiptResponse }: TimelineProps) => {
    const timeline = [
        {
            id: 1,
            content: 'Δημιουργία',
            target: 'παραστατικού',
            href: '#',
            date: new Date(receiptRequest.cbReceiptMoment ?? new Date()).toDateString(),
            datetime: `${new Date(receiptRequest.cbReceiptMoment ?? new Date()).getHours().toString().padStart(2, '0')}:${new Date(receiptRequest.cbReceiptMoment ?? new Date()).getMinutes().toString().padStart(2, '0')}`,
            icon: ComputerDesktopIcon,
            iconBackground: 'bg-gray-400',
        }
    ];
    timeline.push(
        {
            id: 2,
            content: 'Αποστολή',
            target: 'παραστατικού προς myData',
            href: '#',
            date: new Date(receiptResponse.ftReceiptMoment).toDateString(),
            datetime: `${new Date(receiptResponse.ftReceiptMoment).getHours().toString().padStart(2, '0')}:${new Date(receiptResponse.ftReceiptMoment).getMinutes().toString().padStart(2, '0')}`,
            icon: CloudIcon,
            iconBackground: 'bg-gray-400',
        });
    if ((BigInt(receiptRequest.ftReceiptCase) & BigInt(0x0000_0000_0001_0000)) == BigInt(0x0000_0000_0001_0000)) {

    }

    /**
     * Please select a receipt -> Παρακαλώ διαλέξτε απόδειξη ή αναζητήστε στα αριστερά
Connection case (normal with no connection loss)
Timestamp Creation Cash Register  -> Δημιουργία παραστατικού
Timestamp Middleware Middleware -> Αποστολή παραστατικού προς myData
Connection loss case
Timestamp Creation Cash Register  -> Δημιουργία παραστατικού
Timestamp Middleware Middleware -> Αποστολή παραστατικού προς myData
Preloaded receipt case
Timestamp Creation Cash Register  -> Δημιουργία παραστατικού
Timestamp Middleware Middleware -> Αποστολή παραστατικού προς myData
When new invoice created -> Ενημέρωση παραστατικού
Timestamp Middleware Middleware (for updated invoice) -> Αποστολή ενημέρωσης παραστατικού προς myData
     */

    return (
        <div className="flow-root">
            <ul role="list" className="-mb-8">
                {timeline.map((event, eventIdx) => (
                    <li key={event.id}>
                        <div className="relative pb-8">
                            {eventIdx !== timeline.length - 1 ? (
                                <span aria-hidden="true" className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" />
                            ) : null}
                            <div className="relative flex space-x-3">
                                <div>
                                    <span
                                        className={classNames(
                                            event.iconBackground,
                                            'flex size-8 items-center justify-center rounded-full ring-8 ring-white',
                                        )}
                                    >
                                        <event.icon aria-hidden="true" className="size-5 text-white" />
                                    </span>
                                </div>
                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                    <div>
                                        <p className="text-sm text-gray-500">
                                            {event.content}{' '}
                                            <a href={event.href} className="font-medium text-gray-900">
                                                {event.target}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                        <time dateTime={event.datetime}>{event.date} {event.datetime}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export interface Receipt {
    receiptJournal: ftReceiptJournal;
    queueItem: ftQueueItem;
    receiptResponse: ReceiptResponse,
    receiptRequest: ReceiptRequest,
    aadeData: InvoiceParsedResult;
}

export default POSApp;
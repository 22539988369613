import React, { createContext, useState, useContext, ReactNode } from 'react';
import { ErrorDialog } from '../dialogs/ErrorDialog';

// Define the interface for the context
interface ErrorModalContextType {
  showErrorModal: (msg: string) => void;
  hideErrorModal: () => void;
}

// Create the context
export const ErrorModalContext = createContext<ErrorModalContextType | undefined>(undefined);

// Parent component that provides the context
const ErrorModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const showErrorModal = (msg: string) => {
    setErrorMessage(msg);
    setVisible(true);
  };

  const hideErrorModal = () => setVisible(false);

  return (
    <ErrorModalContext.Provider value={{ showErrorModal, hideErrorModal }}>
      {children}
      <ErrorDialog isOpen={visible} title={errorMessage} closeModal={hideErrorModal} />
    </ErrorModalContext.Provider>
  );
};

export default ErrorModalProvider;
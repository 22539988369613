import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import WaitDialogProvider from './hooks/WaitDialogProvider';
import ErrorModalProvider from './hooks/ErrorModalProvider';

export const Fallback = ({ error, resetErrorBoundary }: FallbackProps) =>
(
  <div role="alert">
    <p>Something went wrong:</p>
    <pre style={{ color: "red" }}>{error.message}</pre>
  </div>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  }
])

const queryClient = new QueryClient()


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={Fallback}>
      <QueryClientProvider client={queryClient}>
        <WaitDialogProvider>
          <ErrorModalProvider>
            <RouterProvider router={router} />
          </ErrorModalProvider>
        </WaitDialogProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
reportWebVitals();
import { v4 as uuidv4 } from 'uuid';
var JSONbig = require('json-bigint');

export default interface JournalRequest {
    ftJournalType: bigint;
}

const API_BASE_URL = "https://possystem-api-sandbox.fiskaltrust.eu";

type POSSystemApiErrorResponse = {
    type: string;
    title: string;
    status: number;
    detail: string;
};

const journalAADE = async (cashboxid: string, accesstoken: string) => {
    try {
        const headers = {
            "x-cashbox-id": cashboxid,
            "x-cashbox-accesstoken": accesstoken,
            "x-operation-id": uuidv4(),
            "content-type": "application/json"
        };
        const response = await fetch(`${API_BASE_URL}/v2/journal`, {
            method: 'POST',
            headers: headers,
            body: JSONbig.stringify({
                ftJournalType: BigInt("0x4752200000000000")
            })
        });
        if (response.status > 299) {
            var errorResponse = JSON.parse(await response.text()) as POSSystemApiErrorResponse;
            return errorResponse;
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }

    } catch (error) {
        console.error("Error in signing:", error);
        throw error;
    }
};


const journalReceiptJournals= async (cashboxid: string, accesstoken: string) => {
    try {
        const headers = {
            "x-cashbox-id": cashboxid,
            "x-cashbox-accesstoken": accesstoken,
            "x-operation-id": uuidv4(),
            "content-type": "application/json"
        };
        const response = await fetch(`${API_BASE_URL}/v2/journal`, {
            method: 'POST',
            headers: headers,
            body: JSONbig.stringify({
                ftJournalType: BigInt("0x2")
            })
        });
        if (response.status > 299) {
            var errorResponse = JSON.parse(await response.text()) as POSSystemApiErrorResponse;
            return errorResponse;
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json() as ftReceiptJournal[];
        } else { 
            return await response.text();
        }

    } catch (error) {
        console.error("Error in signing:", error);
        throw error;
    }
};

const journalQueueItems = async (cashboxid: string, accesstoken: string) => {
    try {
        const headers = {
            "x-cashbox-id": cashboxid,
            "x-cashbox-accesstoken": accesstoken,
            "x-operation-id": uuidv4(),
            "content-type": "application/json"
        };
        const response = await fetch(`${API_BASE_URL}/v2/journal`, {
            method: 'POST',
            headers: headers,
            body: JSONbig.stringify({
                ftJournalType: BigInt("0x3")
            })
        });
        if (response.status > 299) {
            var errorResponse = JSON.parse(await response.text()) as POSSystemApiErrorResponse;
            return errorResponse;
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json() as ftQueueItem[];
        } else { 
            return await response.text();
        }

    } catch (error) {
        console.error("Error in signing:", error);
        throw error;
    }
};


const journal = async (journalRequest: JournalRequest, cashboxid: string, accesstoken: string) => {
    try {
        const headers = {
            "x-cashbox-id": cashboxid,
            "x-cashbox-accesstoken": accesstoken,
            "x-operation-id": uuidv4(),
            "content-type": "application/json"
        };
        const response = await fetch(`${API_BASE_URL}/v2/journal`, {
            method: 'POST',
            headers: headers,
            body: JSONbig.stringify(journalRequest)
        });
        if (response.status > 299) {
            var errorResponse = JSON.parse(await response.text()) as POSSystemApiErrorResponse;
            return errorResponse;
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }

    } catch (error) {
        console.error("Error in signing:", error);
        throw error;
    }
};

export { journal, journalAADE, journalQueueItems, journalReceiptJournals };

export interface ftReceiptJournal {
    ftReceiptJournalId: string
    ftReceiptMoment: string
    ftReceiptNumber: number
    ftReceiptTotal: number
    ftQueueId: string
    ftQueueItemId: string
    ftReceiptHash: string
    TimeStamp: number
}

export interface ftQueueItem {
    ftQueueItemId: string
    ftQueueId: string
    ftQueueRow: number
    ftQueueMoment: string
    ftQueueTimeout: number
    ftWorkMoment: string
    ftDoneMoment: string
    cbReceiptMoment: string
    cbTerminalID: string
    cbReceiptReference: string
    country: string
    version: string
    request: string
    requestHash: string
    response: string
    responseHash: string
    TimeStamp: number
}

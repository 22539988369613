import React, { createContext, useState, useContext, ReactNode } from 'react';
import WaitDialog from '../dialogs/WaitDialog';

interface WaitDialogProviderContextType {
  showWaitDialog: (msg: string) => void;
  hideWaitModal: () => void;
}

export const WaitDialogProviderContext = createContext<WaitDialogProviderContextType | undefined>(undefined);

const WaitDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [waitMessage, setWaitMessage] = useState("");

  const showWaitDialog = (msg: string) => {
    setWaitMessage(msg);
    setVisible(true);
  };

  const hideWaitModal = () => setVisible(false);

  return (
    <WaitDialogProviderContext.Provider value={{ showWaitDialog, hideWaitModal }}>
      {children}
      <WaitDialog show={visible} message={waitMessage} />
    </WaitDialogProviderContext.Provider>
  );
};

export default WaitDialogProvider;
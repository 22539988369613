import { Dialog, DialogTitle } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { GenericDialog } from "./GenericDialog";

type ErrorDialogProps = {
    closeModal: () => void;
    title: string;
    isOpen: boolean;
};

export const ErrorDialog = ({ isOpen, closeModal, title, children }: React.PropsWithChildren<ErrorDialogProps>) => {
    return (
        <GenericDialog isOpen={isOpen} closeModal={closeModal}>
            <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <ExclamationTriangleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {title}
                    </DialogTitle>
                    <div className="mt-2">
                        {children}
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => closeModal()}
                >
                    Okay Dokey
                </button>
            </div>
        </GenericDialog>
    )
};
